<template>
  <PageLayout>
    <template #header>
      <k-title>{{ candidate.name }}</k-title>
    </template>
    <template
      v-if="candidate"
      #default
    >
      <k-display-group language-prefix="candidate.fields">
        <v-row>
          <v-col sm="6">
            <k-dossier>
              <v-row>
                <v-col cols="8">
                  <k-text-display field="bsn">
                    {{ candidate.bsn }}
                  </k-text-display>
                  <k-text-display field="iban">
                    {{ candidate.iban }}
                  </k-text-display>
                  <k-text-display field="ibanAscription">
                    {{ candidate.ibanAscription }}
                  </k-text-display>
                </v-col>
              </v-row>
            </k-dossier>
          </v-col>
        </v-row>
      </k-display-group>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';
import { invite } from '@/modules/candidate/api/candidate.js';

export default {
  name: 'CandidateAdministrativeDetails',
  components: {
    KDossier,
    KTitle,
    KDisplayGroup,
    PageLayout,
    KTextDisplay,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  data() {
    return {
      isSending: false,
      alertType: 'success',
      alertMessage: '',
    };
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      }],
    );
  },
  methods: {
    async sendInvitation() {
      if (this.isSending) return;

      this.isSending = true;
      try {
        await invite(this.candidate.id);
        this.alertMessage = this.$t('candidate.dossier.invitationSendSuccess');
        this.alertType = 'success';
      } catch (error) {
        this.alertMessage = this.$t('candidate.dossier.invitationSendError');
        this.alertType = 'error';
      }
      setTimeout(() => this.alertMessage = '', 3000);
      this.isSending = false;
    },
  },
};
</script>
